<template>
  <v-container>
    <v-responsive class="d-flex align-center fill-height">
      <CrispLogo />
      <v-card class="align center text-center ma-3 rounded" color="#4A4A4A" dark>
        <v-card-text class="pa-8 white--text">
          <iframe src="https://crispoo-orders.crispnow.com/tabs/locations" height="600" allow="geolocation;"></iframe>
        </v-card-text>
      </v-card>
    </v-responsive>
  </v-container>
</template>

<style>
.container.width {
  width: 50%;
}

iframe {
  width: 95%;
  height: 600px;
}

@media only screen and (max-width: 916px) {
  .container.width {
    width: 100%;
  }
}
</style>

<script>
import CrispLogo from './CrispLogo.vue';

export default {
  components: {
    CrispLogo
  }
}
</script>