<template>
  <v-container class="fill-height width">
    <v-responsive class="d-flex align-center fill-height">
      <CrispLogo />
      <v-card class="align center text-center ma-3 rounded" color="#1E1E1E" dark>
        <v-card-text class="pa-8 white--text">
            <h1 class="text-h4 font-weight-bold ma-1">Thank you</h1>
            <p class="ma-1 text-h6 font-weight-light">We will contact you as soon as possible</p>
        </v-card-text>
      </v-card>
      <CrispFooter />
    </v-responsive>
  </v-container>
</template>

<style>

.container.width {
  width: 50%;
}

@media only screen and (max-width: 916px) {
  .container.width {
    width: 100%;
  }
}
</style>

<script>
import CrispLogo from './CrispLogo.vue';
import CrispFooter from './CrispFooter.vue';

export default {
  components: {
    CrispLogo,
    CrispFooter,
  }
}

</script>