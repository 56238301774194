
<template>
  <v-container class="fill-height width">
    <v-responsive class="d-flex align-center fill-height">
      <CrispLogo />
      <v-banner class="align-center text-center mb-3" lines="three">
        <p class="font-weight-bold mb-5 text-h4">Crisp is a service provider for restaurants.</p>
        <p class="font-weight-light mb-3">You are visiting this website, because you've seen <span class="font-weight-bold">CRISPPOS.COM</span> on your card or bank statement, and maybe you do not recognize the payment.</p>
        <p class="font-weight-light mb-3">If you want to find out at which restaurant you used your card, please fill in the details below:</p>
      </v-banner>

      <v-form @submit.prevent="submit" v-model="valid" ref="form" lazy-validation>
        <v-container class="font-weight-light">
          <v-row v-if="error">
            <v-col cols="12">
              <v-alert 
                dense 
                outlined 
                type="error"
                class="mb-0">
                {{ error }}
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <label>E-mail*</label>
              <v-text-field 
                class="font-weight-bold" 
                color="black" 
                placeholder="example@gmail.com" 
                v-model="email"
                :rules="emailRules" 
                hide-details 
                outlined 
                required>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" sm="12">
              <v-dialog 
                ref="dialog" 
                width="290px" 
                v-model="modal" 
                :return-value.sync="date" 
                persistent>
                <template v-slot:activator="{ on, attrs }">
                  <label>Date of Payment*</label>
                  <v-text-field 
                    class="font-weight-bold" 
                    color="black" 
                    placeholder="Select date of payment"
                    prepend-inner-icon="mdi-calendar" 
                    v-model="date" 
                    v-bind="attrs" 
                    v-on="on" 
                    :rules="dateRules"
                    readonly 
                    hide-details 
                    outlined 
                    required>
                  </v-text-field>
                </template>
                <v-date-picker 
                  v-model="date" 
                  :allowed-dates="(date) => date <= new Date().toISOString().substr(0, 10)"
                  scrollable>
                  <v-spacer></v-spacer>
                  <v-btn 
                    color="primary" 
                    @click="modal = false" 
                    text>
                    Cancel
                  </v-btn>
                  <v-btn 
                    color="primary" 
                    @click="$refs.dialog.save(date)" 
                    text>
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <label>Amount*</label>
              <v-text-field 
                class="font-weight-bold" 
                color="black" 
                placeholder="Amount of payment" 
                type="number"
                v-model="amount" 
                :rules="amountRules" 
                @keydown="keyCheck($event)" 
                hide-details 
                outlined 
                required>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" sm="12">
              <label>Card Brand*</label>
              <v-select 
                class="font-weight-bold" 
                color="black" 
                placeholder="Select brand of your card"
                v-model="cardBrand" 
                :items="items" 
                :rules="selectRules" 
                hide-details 
                outlined 
                required>
              </v-select>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <label>Last 4 digits of your card*</label>
              <v-text-field 
                class="font-weight-bold" 
                color="black" 
                placeholder="Input exactly 4 digits" 
                type="number"
                v-model="cardLastFour" 
                :rules="cardRules" 
                @keydown="inputStopper($event)" 
                hide-details 
                outlined
                required>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <vue-recaptcha
                ref="invisibleRecaptcha"
                size="invisible" 
                :sitekey="sitekey"
                @error="onError"
                @expired="onExpired"
                @verify="onVerify">
              </vue-recaptcha>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn 
                class="white--text pa-7 text-capitalize text-weight-bold" 
                color="black" 
                type="button" 
                :disabled="isDisabled" 
                @click="submit" 
                block>
                Submit
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <v-col cols="12">
        <CrispFooter />
      </v-col>
    </v-responsive>
  </v-container>
</template>

<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

body {
  font-family: Helvetica, Arial, sans-serif;
}

label {
  margin-left: 10px;
}

.v-input__slot {
  margin-top: 10px;
}

.v-banner-text {
  padding-inline-end: 0;
}

.container.width {
  width: 50%;
}

@media only screen and (max-width: 414px) {
  .container.width {
    width: 100%;
  }
}
</style>

<script>
import CrispLogo from './CrispLogo.vue';
import CrispFooter from './CrispFooter.vue';
import axios from 'axios';
import { VueRecaptcha } from 'vue-recaptcha';

const cardInvalidKeys = [
  69, // 'e' key
  190, // '.' key
  189, // '-' key
  110, // '.' key in numpad
  109, // '-' key in numpad
]

const amountInvalidKeys = [
  69, // 'e' key
  189, // '-' key
  109, // '-' key in numpad
]

const backspaceKey = 8;
const decimalPlaces = 2;

export default {
  components: {
    CrispLogo,
    CrispFooter,
    VueRecaptcha,
  },

  data: () => ({
    valid: true,
    email: '',
    emailRules: [
      v => !!v,
      v => 
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g.test(v),
    ],
    amount: '',
    amountRules: [
      v => !!v,
    ],
    cardBrand: null,
    selectRules: [v => !!v],
    items: [
      'American Express',
      'Diners',
      'Discover',
      'JCB',
      'Mastercard',
      'Visa',
      'Other'
    ],
    cardLastFour: '',
    cardRules: [
      v => !!v,
      v => v.length === 4
    ],
    date: null,
    menu: false,
    modal: false,
    menu2: false,
    dateRules: [v => !!v],
    error: false,
    sitekey: process.env.VUE_APP_RECAPTCHAV2_SITEKEY,
    recaptcha: '',
  }),

  computed: {
    isDisabled() {
      if (this.valid && this.email && this.date && this.amount && this.cardBrand && this.cardLastFour) {
        return false;
      }
      else {
        return true;
      }
    },
  },

  methods: {
    inputStopper(event) {
      if (event.keyCode === backspaceKey) {
        return;
      }
      if (this.cardLastFour.length == 4 || cardInvalidKeys.includes(event.keyCode)) {
        event.preventDefault();
      }
    },
    keyCheck(event) {
      if (event.keyCode === backspaceKey) {
        return;
      }
      if (amountInvalidKeys.includes(event.keyCode) 
        || (this.amount.includes('.') && this.amount.split('.')[1].length == decimalPlaces)
      ) {
        event.preventDefault();
      }
    },
    onVerify(response) {
      axios.post(process.env.VUE_APP_API + '/api/contact/payment-inquiry', {
        email: this.email,
        dateOfPayment: this.date,
        amountOfPayment: this.amount,
        cardBrand: this.cardBrand,
        lastFourDigits: this.cardLastFour,
        recaptchaResponse: response,
      }).then(() => {
        this.$router.push('/confirmation');
        this.$refs.recaptcha.reset();
      }).catch(() => {
        this.error = "Oops, something went wrong. Please try again later.";
        this.valid = false;
      });
    },
    onExpired() {
      this.error = "reCAPTCHA expired. Please refresh the website";
      this.valid = false;
    },
    onError() {
      this.error = "reCAPTCHA encountered an error. Please refresh the website"
    },
    resetRecaptcha() {
      this.$refs.recaptcha.reset();
    },
    async submit() {
      this.$refs.form.validate();
      await this.$refs.invisibleRecaptcha.execute();
    }
  }
}
</script>