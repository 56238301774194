<template>
  <v-footer padless color="transparent">
    <v-row
      justify="center"
      no-gutters
    >
      <v-btn
        v-for="link in links"
        :key="link.title"
        text
        class="my-2"
      >
        <a :href="link.url" class="font-weight-light text-capitalize">{{ link.title }}</a>
      </v-btn>
    </v-row>
  </v-footer>
</template>

<style>
body {
  font-family: Helvetica, Arial, sans-serif;
}

button {
  margin-bottom: 0;
}

a {
  text-decoration: none;
  color: #1E1E1E !important;
}
</style>

<script>
export default {
  data: () => ({
    links: [
      {
        title: 'Terms & Conditions',
        url: 'https://www.crispqsr.com/general-terms-and-conditions'
      },
      {
        title: 'Privacy Policy',
        url: 'https://www.crispqsr.com/privacy-terms-and-conditions'
      },
    ],
  }),
}
</script>
