import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import VueRouter from 'vue-router';
import CrispForm from './components/CrispForm.vue';
import ConfirmationPage from './components/ConfirmationPage.vue';
import IFrameTest from './components/IFrameTest.vue';

Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    {
      path: '/',
      component: CrispForm
    },
    {
      path: '/confirmation',
      component: ConfirmationPage
    },
    {
      path: '/iframetest',
      component: IFrameTest
    }
  ],
  mode: 'history'
})

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
